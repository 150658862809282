import { useCallback, useEffect, useState } from 'react';

import { TagButton } from 'components';
import { useViewBuilder } from 'contexts';
import { useTabs, useTagsQuery } from 'hooks';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import { useSearchParams } from 'react-router-dom';
import { Tag } from 'types';
import { classes } from 'utils';
import debounce from 'utils/debounce';

const useSmartContractList = () => {
  const {
    searchedSC,
    hasMoreSearchedSC,
    searchSC,
    infiniteSearchSC,
    setSelectedSmartContract,
    functionSpecs,
    setFunctionSpecs,
    isSearchLoading,
    isFunctionsLoading,
    selectedSmartContract,
    functionOptions,
    searchCount,
  } = useViewBuilder();
  const [searchString, setSearchString] = useState('');
  const [openedSmartContracts, setOpenedSmartContracts] = useState<string[]>([]);
  const [activeTags, setActiveTags] = useState<Tag[]>([]);
  const [fetching, setFetching] = useState(false);
  const [searchParams] = useSearchParams('');

  const { thresholdElementRef } = useInfiniteScroll({
    fetchNextPage: () => {
      if (hasMoreSearchedSC && !fetching) {
        setFetching(true);
        infiniteSearchSC(searchString, activeTags).then(() => setFetching(false));
      }
    },
    options: { rootMargin: '20px' },
  });

  const debouncefn = useCallback(
    debounce((searchString, activeTags) => {
      searchSC(searchString, activeTags);
    }, 600),
    []
  );

  useEffect(() => {
    debouncefn(searchString, activeTags);
  }, [searchString, activeTags]);

  useEffect(() => {
    const address = searchParams.get('address') as string;
    const network = searchParams.get('network') as string;
    if (address) setSearchString(address);
    if (network) setActiveTags([{ name: network, slug: network }]);
  }, [searchParams]);

  useEffect(() => {
    const address = searchParams.get('address');
    if (address && searchedSC) {
      const smartContract = searchedSC?.find((sc) => sc.address == address);
      setSelectedSmartContract(smartContract);
      setOpenedSmartContracts((prev) => {
        const newAddress = smartContract?.address;
        if (newAddress) {
          return [...prev, newAddress];
        }
        return prev;
      });
    }
  }, [searchedSC]);

  const { data: tags } = useTagsQuery();

  const toggleTag = useCallback((tag: Tag) => {
    setActiveTags((prev) => (prev.some((t) => t.slug === tag.slug) ? [] : [tag]));
  }, []);

  const { tabSelector, tabPanels } = useTabs(
    [
      // {
      //   child: (
      //     <div className="flex flex-wrap gap-[6px]">
      //       {!(isLoading || isTagsLoading) &&
      //         tags?.topTags.map((tag, index) => {
      //           if (index < 5)
      //             return (
      //               <TagButton
      //                 value={tag}
      //                 className="list-none w-fit"
      //                 classNames={{
      //                   button:
      //                     'capitalize text-white text-sm font-normal leading-tight text-white text-sm font-normallowercase leading-tight select-none',
      //                 }}
      //                 toggleTag={toggleTag}
      //                 isActive={activeTags.some((t) => t.slug === tag.slug)}
      //               />
      //             );
      //         })}
      //       {(isLoading || isTagsLoading) &&
      //         arrayOfSize(10).map((el) => (
      //           <Skeleton.Loader isLoading isDarkTheme className="w-[30px] h-6" />
      //         ))}
      //     </div>
      //   ),
      //   label: 'Popular Tags',
      //   value: 'toptag',
      // },
      {
        child: (
          <div className="flex flex-wrap gap-[6px]">
            {tags?.chainTags.map((tag) => (
              <TagButton
                icon={<img src={`/logos/chain/${tag.name?.toLowerCase()}.svg`} alt={tag.slug} />}
                value={tag}
                className="list-none w-fit"
                classNames={{
                  button:
                    'capitalize text-white text-sm font-normal leading-tight text-white text-sm font-normal leading-tight select-none',
                }}
                toggleTag={toggleTag}
                isActive={activeTags.some((t) => t.slug === tag.slug)}
                key={tag.slug}
              />
            ))}
          </div>
        ),
        label: 'Chain Tags',
        value: 'chaintag',
      },
    ],
    0,
    {
      classNames: {
        base: 'w-full whitespace-pre gap-4  !h-fit border-b border-stone-900 p-0 pb-[6px] mt-[17px]',
        tab: (_) =>
          classes(
            'text-white text-xs font-normal h-[22px] !flex-[unset]',
            _ &&
              'relative after:h-[1px] after:w-full after:bg-white  after:left-0 after:bottom-[-8px] after:w-full after:block after:absolute'
          ),
      },
    }
  );

  return {
    searchString,
    setSearchString,
    tabSelector,
    isSearchLoading,
    tabPanels,
    searchedSC,
    searchCount,
    functionSpecs,
    setFunctionSpecs,
    fetching,
    thresholdElementRef,
    setSelectedSmartContract,
    setOpenedSmartContracts,
    functionOptions,
    openedSmartContracts,
    isFunctionsLoading,
    selectedSmartContract,
  };
};

export default useSmartContractList;
