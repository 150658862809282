import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Maybe, TgFunction } from '@watch/common';
import { ListBoxOptionType } from 'components';
import { useApi } from 'contexts';
import {
  DryRunContractMutationFn,
  MergeContractMutation,
  SearchType,
  SmartContract,
  TagsQuery,
  TimegraphTag,
  useDryRunContractMutation,
  useMergeContractMutation,
  useSearchLibraryLazyQuery,
  useTagsQuery,
} from 'gql';
import { UseEditTags, useEditTags, useParamState } from 'hooks';
import { Setter, VoidFn } from 'types';

export type TagType = {
  name: string;
  slug: string;
};

export type FunctionOption = Maybe<TgFunction> & { status: string };

type ListSmartContractContextType = {
  isListSmartContract: boolean;
  setIsListSmartContract: (_?: boolean) => void;
  isConnect: boolean;
  setSmartContractAddress: React.Dispatch<React.SetStateAction<string>>;
  smartContractAddress: string;
  setAbiContent: React.Dispatch<React.SetStateAction<string | undefined>>;
  abiContent: string | undefined;
  smartContractIdentifier: string;
  setSmartContractIdentifier: React.Dispatch<React.SetStateAction<string>>;
  smartContractDescription: string;
  setSmartContractDescription: React.Dispatch<React.SetStateAction<string>>;
  smartContractInstance: ListBoxOptionType | undefined;
  setSmartContractInstance: React.Dispatch<React.SetStateAction<ListBoxOptionType | undefined>>;
  isDryRunContractLoading: boolean;
  dryRunContract: DryRunContractMutationFn;
  isDryRunContractError: string | ApolloError | null | undefined;
  functionOptions: FunctionOption[] | null | undefined;
  selectedChain: TagType | undefined;
  handleChainTagClick: (tag: TagType) => void;
  handleSmartContractInputChange: (value: string) => void;
  listSmartContract: (methods: string[]) => Promise<void>;
  isMergeContractLoading: boolean;
  isMergeContractError: boolean;
  mergeContractResult: MergeContractMutation | null | undefined;
  editTags: UseEditTags;
  error: string | undefined;
  setSelectedChain: Setter<TagType | undefined>;
  isSmartContractAlreadyListed: boolean;
  setMergeContractResult: Setter<MergeContractMutation | null | undefined>;
  setIsMergeContractError: Setter<boolean>;
  isError: boolean;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
  tags: TagsQuery | undefined;
  clearDryRunContractResult?: VoidFn;
};

const ListSmartContractContext = createContext<ListSmartContractContextType | undefined>({
  smartContractIdentifier: '',
} as ListSmartContractContextType);

export const ListSmartContractsProvider = ({ children }: { children: React.ReactNode }) => {
  const { account, sessionKey } = useApi();

  const [isListSmartContract, setIsListSmartContract] = useParamState<boolean>(
    'list-smart-contracts',
    false
  );
  const [isConnect] = useParamState<boolean>('connect', false);
  const [smartContractAddress, setSmartContractAddress] = useState('');
  const [smartContractIdentifier, setSmartContractIdentifier] = useState('');
  const [smartContractDescription, setSmartContractDescription] = useState('');
  const [smartContractInstance, setSmartContractInstance] = useState<
    ListBoxOptionType | undefined
  >();
  const [error, setError] = useState<string | undefined>();
  const [abiContent, setAbiContent] = useState<string | undefined>();
  const [selectedChain, setSelectedChain] = useState<TagType | undefined>();
  const [isMergeContractError, setIsMergeContractError] = useState(false);
  const [mergeContractResult, setMergeContractResult] = useState<
    MergeContractMutation | null | undefined
  >();
  const [isError, setIsError] = useState<boolean>(false);
  const [isSmartContractAlreadyListed, setIsSmartContractAlreadyListed] = useState<boolean>();

  const editTags = useEditTags();

  const { data: tags } = useTagsQuery();

  const [
    dryRunContract,
    {
      data: dryRunContractResult,
      loading: isDryRunContractLoading,
      reset: clearDryRunContractResult,
      error: isDryRunContractError,
    },
  ] = useDryRunContractMutation();

  const [
    mergeContract,
    { data: mergeContractRawResult, loading: isMergeContractLoading, error: mergeContractError },
  ] = useMergeContractMutation({
    refetchQueries: ['Resource', 'SearchLibrary', 'searchLibraryCount', 'MyProfile'],
  });

  const functionOptions = useMemo(() => {
    if (isDryRunContractLoading || !dryRunContractResult) {
      return undefined;
    }
    try {
      return dryRunContractResult.dryRunContract?.functions?.map((fn: any) => ({
        ...fn.function,
        status: fn.status,
      }));
      // ?.filter((fn: any) => fn.status === 'Proposed')
    } catch (e) {
      console.error('Error processing function options:', e);
      return null;
    }
  }, [dryRunContractResult, isDryRunContractLoading]);

  const handleSmartContractInputChange = (value: string) => {
    setSmartContractAddress(value.replace('0x', ''));
  };

  const handleChainTagClick = (tag: TagType) => {
    setSelectedChain((prev) => (prev?.slug !== tag.slug ? tag : undefined));
  };

  const [SearchLibrary, { data: libraryData }] = useSearchLibraryLazyQuery({
    variables: {
      filters: {
        searchType: SearchType.SmartContracts,
      },
    },
  });

  const listSmartContract = async (methods: string[]) => {
    try {
      await mergeContract({
        variables: {
          data: {
            contractAddress: smartContractAddress,
            network: selectedChain?.slug || 'mainnet',
            identifier: smartContractIdentifier,
            description: smartContractDescription.trim(),
            methods: methods,
            chain: smartContractInstance?.value || 'mainnet',
            abi: abiContent,
            scope: 'global',
            tags: editTags.tags.filter(
              ({ slug, isNew }) =>
                !editTags.chainTags?.some(({ slug: chainSlug }) =>
                  isNew ? slug === chainSlug : slug.split('-')[0] === chainSlug
                )
            ),
          },
          address: account?.address,
          sessionKey: sessionKey,
        },
        onCompleted(data) {
          if (data.MergeContract.status !== 'Completed') {
            setError('Error listing smart contract');
            setIsMergeContractError(true);
          } else {
            setIsMergeContractError(false);
          }
        },
        refetchQueries: ['SmartContractFunctions', 'SearchLibrary', 'searchLibraryCount'],
      });
    } catch (error) {
      console.error('Error while listing smart contract:', error);
    }
  };

  useEffect(() => {
    if (
      libraryData?.searchLibrary.data[0]?.__typename === 'SmartContract' &&
      libraryData?.searchLibrary.data[0].address &&
      libraryData?.searchLibrary.data[0].address.replace('0x', '') ===
        smartContractAddress.replace('0x', '')
    ) {
      const data = libraryData.searchLibrary.data[0] as SmartContract;
      const identifier = data.name || '';
      const chain: string = data.networks[0] as string;
      const tags = data?.tags || [];
      setSmartContractDescription(data?.description || '');
      setSmartContractIdentifier(identifier.replaceAll(' ', '') || '');
      setSelectedChain({ slug: chain.split('-')[0], name: chain.split('-')[0] });
      editTags.setTags(
        tags
          ?.map((el: Maybe<TimegraphTag>) => ({
            isNew: false,
            slug: el?.tagName as string,
            name: el?.tagName,
          }))
          ?.filter((tag) => !editTags.chainTags?.some((el) => el.slug === tag.slug))
      );
      setIsSmartContractAlreadyListed(true);
    } else if (isSmartContractAlreadyListed) {
      setSmartContractDescription('');
      setSmartContractIdentifier('');
      editTags.setTags([]);
      setIsSmartContractAlreadyListed(false);
    }
  }, [libraryData?.searchLibrary, smartContractAddress]);

  useEffect(() => {
    if (smartContractAddress.trim().length === 40) {
      SearchLibrary({
        variables: {
          filters: {
            searchTerm: smartContractAddress,
            searchType: SearchType.SmartContracts,
            tags: selectedChain ? [selectedChain?.slug.split('-')[0]] : [],
          },
          limit: 1,
        },
        fetchPolicy: 'cache-and-network',
      });
    }
  }, [smartContractAddress, selectedChain]);

  useEffect(() => {
    if (!isListSmartContract) {
      setSmartContractAddress('');
      setSmartContractIdentifier('');
      setSmartContractDescription('');
      setSmartContractInstance(undefined);
      setError(undefined);
      setAbiContent(undefined);
      setSelectedChain(undefined);
      editTags?.setTags([]);
      setIsMergeContractError(false);
      setMergeContractResult(undefined);
    }
  }, [isListSmartContract]);

  useEffect(() => {
    setIsMergeContractError(!!mergeContractError);
  }, [mergeContractError]);

  useEffect(() => {
    if (mergeContractRawResult) {
      setMergeContractResult(mergeContractRawResult);
    }
  }, [mergeContractRawResult]);

  return (
    <ListSmartContractContext.Provider
      value={{
        isListSmartContract,
        setIsListSmartContract,
        isConnect,
        setSmartContractAddress,
        smartContractAddress,
        abiContent,
        setAbiContent,
        smartContractIdentifier,
        setSmartContractIdentifier,
        smartContractDescription,
        setSmartContractDescription,
        smartContractInstance,
        setSmartContractInstance,
        isDryRunContractLoading,
        dryRunContract,
        isDryRunContractError: dryRunContractResult?.dryRunContract?.error || isDryRunContractError,
        functionOptions,
        selectedChain,
        handleChainTagClick,
        handleSmartContractInputChange,
        listSmartContract,
        isMergeContractLoading,
        isMergeContractError,
        mergeContractResult,
        editTags,
        error,
        setSelectedChain,
        isSmartContractAlreadyListed:
          !!libraryData?.searchLibrary.data.length && smartContractAddress.trim().length === 40,
        setIsMergeContractError,
        setMergeContractResult,
        isError,
        setIsError,
        tags,
        clearDryRunContractResult,
      }}
    >
      {children}
    </ListSmartContractContext.Provider>
  );
};

export const useListSmartContract = () =>
  useContext<ListSmartContractContextType>(
    ListSmartContractContext as React.Context<ListSmartContractContextType>
  );
