import { useMemo, useState } from 'react';

import { PageControl } from 'components';
import { PageSizeControl } from 'components/PageSizeControl';
import { useDataDesignQuery } from 'gql';
import { useParams } from 'react-router-dom';

const useViewList = () => {
  const { id, network } = useParams();
  const startPageIndex = 1;
  const [pageIndex, setPageIndex] = useState<number>(startPageIndex);
  const [pageSize, setPageSize] = useState<number>(3);
  const { data, loading: isLoading } = useDataDesignQuery({
    variables: {
      filters: {
        smartContractAddress: id,
        network,
      },
      limit: pageSize,
      page: pageIndex,
    },
  });

  const maxPageIndex = useMemo(
    () =>
      data?.dataDesign?.count
        ? data?.dataDesign?.count < pageSize
          ? 1
          : Math.ceil(data?.dataDesign?.count / pageSize)
        : 1,
    [data?.dataDesign?.count, pageSize]
  );
  const controls =
    data?.dataDesign?.count && data?.dataDesign?.count > 3 ? (
      <div className="flex justify-between md:items-center items-start mt-4 mb-5 h-10 text-sm md:flex-row flex-col md:gap-0 gap-3">
        <PageControl
          onPrevious={() => setPageIndex((prev) => Math.max(1, prev - 1))}
          onNext={() => setPageIndex((prev) => Math.min(maxPageIndex, prev + 1))}
          pageIndex={pageIndex - 1}
          setPageIndex={setPageIndex}
          pageCount={maxPageIndex}
          totalItems={data?.dataDesign?.count || 0}
          pageSize={pageSize}
        />
        <PageSizeControl
          pageSize={pageSize}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          startPageIndex={startPageIndex}
          className="flex items-center justify-center gap-[6px]"
        />
      </div>
    ) : null;
  return { isLoading, viewList: data?.dataDesign?.data, controls };
};

export default useViewList;
