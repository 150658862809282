import { Tab } from '@headlessui/react';
import {
  Button,
  Details,
  DocumentViewIcon,
  Header,
  Input,
  MagnifyingGlassIcon,
  Skeleton,
  Summary,
} from 'components';
import { HTMLAttributes } from 'types';
import { arrayOfSize, classes, pluralize } from 'utils';

import { FunctionList } from './components/FunctionList';
import useSmartContractList from './useSmartContractList';

export const SmartContractList = (props: HTMLAttributes<HTMLDivElement>) => {
  const {
    searchString,
    setSearchString,
    tabSelector,
    isSearchLoading,
    tabPanels,
    searchedSC,
    searchCount,
    functionSpecs,
    setFunctionSpecs,
    fetching,
    thresholdElementRef,
    setSelectedSmartContract,
    setOpenedSmartContracts,
    functionOptions,
    openedSmartContracts,
    isFunctionsLoading,
    selectedSmartContract,
  } = useSmartContractList();
  return (
    <div
      {...props}
      className="border-r-solid flex flex-1  lg:max-w-[329px] h-full shrink-0 flex-col overflow-y-auto overflow-x-hidden border-0 lg:border-r border-r-0 border-solid border-[#212121] no-scrollbar max-h-[calc(100vh-278px)] lg:max-h-[unset]"
    >
      <div className="px-6 pt-4 flex-0 sticky top-0 z-10 bg-black rounded-tl rounded-tr rounded-[25px]">
        <Skeleton.Loader
          isDarkTheme
          containerClassName="lg:flex hidden"
          className="w-[126px] h-[22px]"
        >
          <h5 className="lg:flex hidden">Smart Contracts</h5>
        </Skeleton.Loader>
        <Skeleton.Loader isDarkTheme className="w-full h-10 mt-2 !rounded-full">
          <Input
            dataCy="input-search-view-builder"
            className="relative pl-8 pr-2 lg:mt-6 mt-0 lg:bg-[#0B0B0B] bg-[#060606]"
            isClearable
            value={searchString}
            onChange={(e) => {
              const filteredValue = e.replace(/[^a-zA-Z0-9_]/g, ''); // Allow only letters, digits and underscores
              setSearchString(filteredValue);
            }}
            placeholder="Search by name or address"
            isDarkTheme
          >
            <MagnifyingGlassIcon className="absolute left-4 top-2 h-5 w-5 text-neutral-400" />
          </Input>
        </Skeleton.Loader>
        <Tab.Group>
          <Header
            accessory={
              <Skeleton.Loader isDarkTheme className="mt-4" containerClassName="h-fit">
                {tabSelector}
              </Skeleton.Loader>
            }
            accessoryClassname="!w-full"
          ></Header>
          <div>{tabPanels}</div>
        </Tab.Group>

        <div className="flex justify-between border-b border-[#1E1E1E]">
          <Skeleton.Loader
            isDarkTheme
            isLoading={isSearchLoading}
            className="w-[52px] h-[18px] my-4"
          >
            <p
              className={classes(
                'text-xs text-[#B2B3B8] lg:pt-4 pt-[14px] lg:pb-[13px] pb-[16px] ',
                !searchedSC?.length && 'border-b-0'
              )}
            >
              {searchedSC && `${searchCount || 0} ${pluralize('Result', searchCount || 0)}:`}
            </p>
          </Skeleton.Loader>
          <Skeleton.Loader isDarkTheme className="w-[52px] h-[22px] mb-2">
            {functionSpecs?.length > 0 && (
              <Button
                variant="plain"
                classNames={{
                  base: 'lg:pt-4 pt-[14px] lg:pb-[13px] pb-[16px]',
                  container: 'text-xs text-[#B15EBE]',
                }}
                onClick={() => setFunctionSpecs([])}
              >
                Clear all
              </Button>
            )}
          </Skeleton.Loader>
        </div>
      </div>
      <Skeleton.Provider isLoading={isSearchLoading}>
        <ul className="w-full flex-1 text-white lg:flex-[3] overflow-y-scroll scrollbar-white px-6 ">
          {(isSearchLoading ? arrayOfSize(10).map(() => undefined) : searchedSC)?.map(
            (sc, index) => {
              return (
                <li
                  key={sc?.address}
                  {...(searchedSC?.length === index + 1 && !fetching
                    ? { ref: thresholdElementRef }
                    : null)}
                >
                  <button
                    className={classes('w-full items-center text-sm border-[#1E1E1E]')}
                    onClick={() => {
                      setSelectedSmartContract(sc);
                      setOpenedSmartContracts((prev) => {
                        const newAddress = sc?.address;
                        if (newAddress && !prev.includes(newAddress)) {
                          return [...prev, newAddress];
                        }
                        return prev;
                      });
                    }}
                  >
                    <Details
                      open={
                        !!functionOptions?.length &&
                        (searchString == sc?.name || searchString == sc?.address)
                      }
                      className="border-none"
                    >
                      <Summary
                        isLoading={
                          isFunctionsLoading && selectedSmartContract?.address === sc?.address
                        }
                        className={'flex-1 flex border-none !bg-white'}
                      >
                        <Skeleton.Loader isDarkTheme className="w-6 h-6">
                          <DocumentViewIcon />
                        </Skeleton.Loader>
                        <div
                          data-cy={`${sc?.name}`}
                          className="flex-1 flex-row justify-between my-1 break-words line-clamp-2 ml-[14px] text-[14px]"
                          style={{ wordBreak: 'break-word' }}
                        >
                          <Skeleton.Loader isDarkTheme containerClassName="w-full max-w-[158px]">
                            {sc?.name}
                          </Skeleton.Loader>
                        </div>
                      </Summary>
                      {functionOptions && openedSmartContracts.includes(sc?.address as string) && (
                        <FunctionList smartContractAddress={sc?.address as string} />
                      )}
                    </Details>
                  </button>
                </li>
              );
            }
          )}
          <Skeleton.Provider isLoading={fetching}>
            {fetching &&
              arrayOfSize(5).map((_, i) => {
                return (
                  <li className="flex gap-2 w-full py-[18px] items-center border-[#1E1E1E]" key={i}>
                    <Skeleton.Loader isDarkTheme className="w-6 h-6" />
                    <Skeleton.Loader isDarkTheme containerClassName="w-full" />
                    <Skeleton.Loader isDarkTheme className="w-6 h-6" />
                  </li>
                );
              })}
          </Skeleton.Provider>
        </ul>
      </Skeleton.Provider>
    </div>
  );
};
