import { BreadCrumbs, Button, PaginationControls, Skeleton, WalletWithTimeIcon } from 'components';
import { useApi } from 'contexts';
import { useBreadCrumbsChild } from 'hooks/useBreadCrumbs';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routesConst';
import { classes } from 'utils';

import { BalanceHistoryRow } from './BalanceHistoryRow/BalanceHistoryRow';
import useBalanceHistory from './useBalanceHistory';

export const BalanceHistory = () => {
  useBreadCrumbsChild({ page: 'BalanceHistory' }, [{ page: 'MyProfile' }]);

  const {
    balanceHistoryData,
    isBalanceHistoryLoading: isLoading,
    startPageIndex,
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
  } = useBalanceHistory();

  const navigate = useNavigate();
  const {
    account,
    chainProps: { tokenDecimals },
  } = useApi();

  const count = balanceHistoryData?.balanceHistory?.count;

  return (
    <div className="w-full lg:px-16 justify-start">
      <BreadCrumbs />
      <div
        data-cy="balance-history-page-label"
        className=" text-white md:text-[32px] text-2xl md:mb-4 mb-3 md:leading-10 leading-9"
      >
        Balance History
      </div>
      <Skeleton.Provider isLoading={isLoading || !tokenDecimals}>
        <div
          className={classes(
            'card md:p-5 p-0 sm:rounded-3xl rounded-2xl lg:!border lg:!border-solid !border-[#1F1F1F] !border-none lg:bg-gradient-to-b from-[#000_20.37%] to-[#000_79.34%] md:!bg-[#0E0E0E] !bg-[unset] ',
            !count &&
              !isLoading &&
              !!tokenDecimals &&
              'md:min-h-[410px] min-h-[225px] flex items-center justify-center bg-gradient-to-b from-black to-black shadow-inner !border-solid !border-[#1F1F1F]'
          )}
        >
          {!!count && !isLoading && (
            <div className="lg:block md:hidden hidden">
              <div className="grid grid-cols-[18%_26%_26%_18%_12%] text-[#B2B3B8] text-xs sm:pl-5 sm:pr-6 px-4">
                <Skeleton.Loader isDarkTheme className="w-14 h-3">
                  <div>Type</div>
                </Skeleton.Loader>
                <Skeleton.Loader isDarkTheme className="w-[75px] h-3 !rounded-full">
                  <div>Timestamp</div>
                </Skeleton.Loader>
                <Skeleton.Loader isDarkTheme className="w-[70px] h-3 !rounded-full">
                  <div>Total Amounts</div>
                </Skeleton.Loader>
                <Skeleton.Loader isDarkTheme className="w-[70px] h-3 !rounded-full">
                  <div>Total Rewards</div>
                </Skeleton.Loader>
              </div>
            </div>
          )}

          {!count && !isLoading && !!tokenDecimals && (
            <div className="flex items-center justify-center flex-col">
              <WalletWithTimeIcon />
              <div className="text-center text-white text-xl font-normal flex flex-col gap-1 leading-7 md:mt-[18px] mt-[14px] mb-[14px]">
                We haven&apos;t recorded any transaction history yet.
                <span className="text-base text-[#B2B3B8]">
                  To add funds, simply click on &apos;Deposit&apos;.
                </span>
              </div>
              <Button
                variant="darkThemeFilled"
                onClick={() => {
                  navigate(routes.profile);
                  sessionStorage.setItem('isModalOpen', true.toString());
                }}
                isDisabled={!account?.address}
              >
                Deposit
              </Button>
            </div>
          )}
          {(!!count || isLoading || !tokenDecimals) && (
            <>
              <div className="text-white lg:pt-[10px] md:pt-[0px] pt-0">
                {(isLoading || !tokenDecimals
                  ? new Array(pageSize).fill(null)
                  : balanceHistoryData?.balanceHistory?.balanceHistory || []
                ).map((balanceHistory, index) => (
                  <BalanceHistoryRow row={balanceHistory} key={index} />
                ))}
              </div>
              <PaginationControls
                count={count || 0}
                isLoading={isLoading}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                pageSize={pageSize}
                setPageSize={setPageSize}
                startPageIndex={startPageIndex}
              />
            </>
          )}
        </div>
      </Skeleton.Provider>
    </div>
  );
};
export default BalanceHistory;
